@import './variables';

@import "@ng-select/ng-select/themes/default.theme.css";

/* Core Data Grid CSS */
@import '@ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import '@ag-grid-community/styles/ag-theme-quartz.css';

.ag-theme-quartz {

  /* then add back a border between rows */

  --ag-row-border-width: 1px;
  --ag-row-border-color: rgb(208, 208, 208);
  /* and between columns */
  --ag-cell-horizontal-border: solid rgb(208, 208, 208);
}

/*
    BOOTSTRAP STYLE
*/
$primary: rgb(39, 84, 149);
$secondary: #5DA1E0;
@import "/node_modules/bootstrap/scss/bootstrap";

.btn-secondary {
  color: white;
}


.ng-select.ng-select-single .ng-select-container {
  height: 30px;
  min-height: 30px;
}