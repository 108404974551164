@import '/src/styles/_variables';
@import '/src/styles/_vendors';
@import '/src/styles/_common';
@import '/src/styles/_error';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--vela-light-grey-2);
}

.primary {
  color: var(--vela-primary);
}

.error {
  color: var(--vela-error)
}

a {
  color: var(--vela-primary) !important;
  text-decoration: none !important;
  font-weight: 400 !important;

  &:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
    ;
  }
}

h1 {
  font-weight: 400;
}