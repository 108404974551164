@import 'variables';

.separator {
  background-color: var(--vela-light-grey-1);
  height: 1px;
  margin: 0 10px;
}

.vela-card {
  background-color: var(--vela-white);
  box-shadow: 0 0.2rem 0.45rem 0 var(--vela-light-grey-1), 0 0.0375rem 0.1125rem 0 var(--vela-light-grey-1);
  padding: 1rem;
  border-radius: 0.25rem;
  max-height: 86vh;
  height: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

// For Toolbar
.action-items {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  column-gap: 10px;
  align-items: center;
}

.vela-icon-button {
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  color: var(--vela-primary);


  &:disabled {
    .svg-inline--fa {
      color: var(--vela-dark-grey);
    }
  }
}

.action-items>*:not(:last-child) {
  margin-right: 5px;
}

.custom-menu {
  position: relative;

  &__sub-menu-container {
    position: absolute;
    right: 0;
    z-index: 1000;
    width: 200px;
    max-height: 0;
    overflow: hidden;
    margin: 5px;
    box-shadow: 0 0.2rem 0.45rem 0 var(--vela-light-grey-1),
      0 0.0375rem 0.1125rem 0 var(--vela-light-grey-1);

    &.open-menu {
      max-height: 400px;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  &__sub-menu {
    background-color: var(--vela-white);
  }

  &__header {
    padding: 4px 16px;
    background-color: #fafafa;

    &-content {
      color: #0000008a;
    }

    &-text {
      font-size: 15px;
      font-weight: 600;
      line-height: 36px
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    &-container {
      display: flex;
      align-items: center;
      column-gap: 10px;
      padding: 10px 16px;

      &:hover {
        background-color: var(--vela-light-grey-2);
        cursor: pointer;
      }
    }

    &-text {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}


/** COLORS **/
.color-primary {
  color: var(--vela-primary);
}

.color-red-1 {
  color: var(--vela-red-1);
}


.color-red-2 {
  color: var(--vela-red-2);
}

.color-red-3 {
  color: var(--vela-red-3);
}


.color-brown-1 {
  color: var(--vela-brown-1);
}

.color-green-1 {
  color: var(--vela-green-1);
}

.color-grey {
  color: var(--vela-dark-grey);
}

.color-yellow-1 {
  color: var(--vela-yellow-1);
}

.color-orange-1 {
  color: var(--vela-orange-1);
}


.side-panel {
  border: 1px solid var(--vela-light-grey-1);
  border-radius: 0 8px 8px 0;
  max-height: 100%;
  overflow: auto;
}

.clear-button {
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  margin: 0 10px 0 0;
  color: var(--vela-primary)
}

.todo-container {
  background-color: rgb(248, 249, 250);
  box-shadow:
    0 0.2rem 0.45rem 0 var(--vela-light-grey-1),
    0 0.0375rem 0.1125rem 0 var(--vela-light-grey-1);
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .badge {
    font-size: 16px;
    width: 35px;
    display: flex;
    justify-content: center;
  }

  .item-label {
    margin-bottom: 0;
    margin-left: 20px;
    font-size: 16px;
  }
}

.resource-select {
  min-width: 200px;
}

.dashboard {
  &-container-fluid {
    overflow: auto;
    height: 100%;
  }

  &-row {
    margin-bottom: 10px;
  }

  &-card {
    background-color: var(--vela-white);
    box-shadow: 0 0.2rem 0.45rem 0 var(--vela-light-grey-1), 0 0.0375rem 0.1125rem 0 var(--vela-light-grey-1);
    border-radius: 0.25rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    min-height: 280px;

    &-header {
      background: var(--vela-off-white);
      border-bottom: 1px solid var(--vela-light-slate-blue);
      height: 38px;
      padding: 8px 18px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &-content {
      padding: 1.3rem;
    }

    &-item-label {
      margin-bottom: 0;
      margin-left: 20px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.vela-tab-group-container {
  height: 95%;
}

.error-space {
  min-height: 20px;
}

.configuration-container {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;
}

input[type="color"] {
  visibility: hidden;
}

.color-square {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.subparagraph-info {
  font-size: 12px;
  color: #666;
  margin-left: 1em;
}

.radio-inline {
  display: inline-block;
  margin-right: 1rem;
}

// status labels 
.status-label {
  border: 1px solid;
  font-weight: 500;
  border-radius: 8px;
  line-height: 16px;
  padding: 3px 8px;
  text-align: center;
}

/* Status-specific styles */
.state-blue {
  background-color: rgba(0, 84, 158, 0.12);
  /* Opacity .12 */
  border-color: rgba(0, 84, 158, 0.35);
  /* Opacity .35 */
  color: rgba(0, 84, 158, 1);
  /* Opacity 1 */
}

.state-green {
  background-color: rgba(40, 167, 69, 0.1);
  /* Light green background */
  color: rgba(40, 167, 69, 1);
  /* Opacity 1 */
  border-color: rgba(40, 167, 69, 0.35);
  /* Opacity .35 */
}

.state-red {
  background-color: rgba(220, 53, 69, 0.1);
  /* Light red background */
  color: rgba(220, 53, 69, 1);
  /* Opacity 1 */
  border-color: rgba(220, 53, 69, 0.35);
  /* Opacity .35 */
}

.state-orange {
  background-color: rgba(231, 180, 28, 0.12);
  /* Light orange background */
  color: rgba(231, 180, 28, 1);
  /* Full opacity */
  border-color: rgba(231, 180, 28, 0.35);
  /* Opacity .35 for yellow */
}

.state-grey {
  background-color: rgba(163, 163, 163, 0.12);
  color: rgba(163, 163, 163, 1);
  border-color: rgba(163, 163, 163, 0.35);
}

.state-light-blue {
  background-color: rgba(93, 161, 224, 0.12);
  color: rgba(93, 161, 224, 1);
  border-color: rgba(93, 161, 224, 0.35);
}

.state-yellow {
  background-color: rgba(229, 229, 79, 0.08);
  color: #B8B83F;
  /* Darker shade of yellow */
  border-color: rgba(229, 229, 79, 0.35);
}

.vertical-separator {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  margin: 0 16px;
}

.action-toolbar-separator {
  display: inline-block;
  width: 1px;
  height: 24px; 
  background-color: #a8a5a5; 
  margin: 0 8px; 
  vertical-align: middle;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.modal-header {
  background-color: var(--vela-secondary);
  color: white
}