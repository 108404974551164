.error-container {
  display: grid;
  height: 100vh;

  &-content {
    display: flex;
    margin: auto;
    align-items: center;
  }

  h1 {
    font-size: 6rem;
    line-height: 1;
    font-weight: 300;
    margin-right: 1.5rem;
  }

  h4 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    font-weight: 400;
  }

  a {
    font-weight: 700;
  }
}
