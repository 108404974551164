:root {

  // === COLORS
  --vela-white: #FFFFFF;
  --vela-off-white: #F8F9FA;

  --vela-black: #000000;
  //primary
  --vela-primary: #275495;
  --vela-secondary: #5DA1E0;

  //orange
  --vela-orange-1: #D6A419;
  --vela-orange-2: #ffebbd;
  --vela-orange-3: #f8b883;


  // Grey
  --vela-grey-3: #E5E5E5;

  //RED
  --vela-red-1: #a21c46;
  --vela-red-2: #ed2939;
  --vela-red-3: #f67374;

  --vela-error: #dc3545;

  //brown
  --vela-brown-1: #877555;

  //Blue
  --vela-sky-blue-1: #dae7f2;
  --vela-sky-blue-2: #b7cee3;
  --vela-dark-blue-1: #10161F;

  //Green
  --vela-green-1: #198754;
  --vela-green-2: #e6f4f1;

  //Yellow
  --vela-yellow-1: #ffd700;


  // === GRAY
  --vela-light-grey-1: #CFCFCF;
  --vela-light-grey-2: #F8F8F8;
  --vela-light-grey-3: #EAEAEA;
  --vela-light-grey-4: #cecfd0;

  --vela-slate-grey: #9AA1AB;

  --vela-dark-grey: #a9a9a9;
  --vela-dark-grey-2: #495057;


  --vela-light-slate-blue: #DEE2E6;


  // === FONT
  --vela-font-family: "Muli", sans-serif;
  --vela-font-size-smaller: 0.75rem;
  --vela-font-size-small: 0.85rem;
  --vela-font-size-base: 1rem;
  --vela-font-size-medium: 1.2rem;
  --vela-font-size-large: 1.5rem;
  --vela-font-size-xlarge: 2rem;

  // Font Weight
  --vela-font-weight-thin: 100;
  --vela-font-weight-extra-light: 200;
  --vela-font-weight-light: 300;
  --vela-font-weight-normal: 400;
  --vela-font-weight-medium: 500;
  --vela-font-weight-semi-bold: 600;
  --vela-font-weight-bold: 700;
  --vela-font-weight-extra-bold: 800;
  --vela-font-weight-black: 900;


  --vela-blue-primary-hover: 22, 77, 186;
  --vela-blue-primary-disabled: 183, 205, 247;
  --vela-blue-secondary-hover: 237, 242, 253;


  --vela-btn-hover-darken-percent: 15%;

}